<template>
  <div>
    <a-card :title="$t('复核包装')">
      <a-button slot="extra" type="primary" ghost @click="goBack"><a-icon type="left" />{{ $t("返回") }}</a-button>

      <a-row gutter="12">
        <a-col :span="4" style="width: 256px; margin-bottom: 12px">
          <a-input-search v-model="filterValue" :placeholder="$t('请扫描产品条码')" />
        </a-col>
      </a-row>

      <a-spin :spinning="loading">
        <div>
          <a-table
            rowKey="id"
            size="small"
            :columns="curColumns"
            :data-source="filteredMaterialItems"
            :pagination="false"
            :scroll="{ x: 100 }"
          >
            <div slot="total_quantity" slot-scope="value, item">
              <a-input-number v-model="item.total_quantity" size="small" @change="changeQuantity(item)" />
            </div>
            <div slot="charge_value" slot-scope="value, item">
              <a-input-number v-model="item.charge_value" size="small" />
            </div>
            <div slot="packing_number" slot-scope="value, item">
              <a-input v-model="item.packing_number" size="small" />
            </div>
            <div slot="action" slot-scope="value, item, index">
              <a-button-group size="small">
                <a-button type="danger" @click="removeMaterial(index)">{{ $t("删除") }}</a-button>
                <a-button type="primary" @click="copyMaterial(item, index)">{{ $t("复制") }}</a-button>
              </a-button-group>
            </div>
          </a-table>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="loading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import { deliveryMaterialOption } from "@/api/option";
import { deliveryRecordCreate } from "@/api/stockOut";
import NP from "number-precision";

export default {
  data() {
    return {
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          customRender: (value, item, index) => index + 1,
          width: 60,
          fixed: "left",
        },
        {
          title: this.$t("产品名称"),
          dataIndex: "material_name",
          width: 150,
          fixed: "left",
        },
        {
          title: this.$t("箱号"),
          dataIndex: "packing_number",
          scopedSlots: { customRender: "packing_number" },
          width: 180,
        },
        {
          title: this.$t("复核数量"),
          dataIndex: "total_quantity",
          scopedSlots: { customRender: "total_quantity" },
          width: 120,
        },
        {
          title: this.$t("计费量"),
          dataIndex: "charge_value",
          scopedSlots: { customRender: "charge_value" },
          width: 120,
          key: "charge_value",
        },
        {
          title: this.$t("产品编号"),
          dataIndex: "material_number",
          width: 180,
        },

        {
          title: this.$t("待复核数量"),
          dataIndex: "remain_quantity",
          width: 160,
        },
        {
          title: this.$t("批次"),
          dataIndex: "batch_number",
          width: 180,
        },
        {
          title: this.$t("单位"),
          dataIndex: "unit",
          width: 80,
        },
        {
          title: this.$t("计费单位"),
          dataIndex: "charge_unit_display",
          width: 100,
          key: "charge_unit",
        },
        {
          title: this.$t("操作"),
          dataIndex: "action",
          fixed: "right",
          scopedSlots: { customRender: "action" },
          width: 120,
        },
      ],
      loading: false,
      delivery_order_id: "",
      deliveryMaterialItems: [],
      filterValue: "",
    };
  },
  computed: {
    filteredMaterialItems() {
      let filteredMaterialItems = [];
      for (let item of this.deliveryMaterialItems) {
        if (item.material_number.includes(this.filterValue)) {
          filteredMaterialItems.push(item);
        }
      }

      if (this.filterValue && this.filterValue != "" && filteredMaterialItems.length == 0) {
        this.$message.warn(`${this.$t("产品")}[${this.filterValue}]${this.$t("不存在")}`);
      }

      return filteredMaterialItems;
    },
    enableCharge() {
      return this.$store.state.user.enableCharge;
    },
    curColumns() {
      const items = [];
      for (const item of this.columns) {
        if (!this.enableCharge && item.key === "charge_value") {
          continue;
        }

        if (!this.enableCharge && item.key === "charge_unit") {
          continue;
        }

        items.push(item);
      }
      return items;
    },
  },
  methods: {
    moment,
    initData() {
      this.delivery_order_id = this.$route.query.id;
      this.getMaterial();
    },
    getMaterial() {
      deliveryMaterialOption({
        page_size: 999999,
        delivery_order: this.$route.query.id,
        is_completed: false,
      })
        .then((data) => {
          let materialItems = data.results.map((item) => {
            return {
              ...item,
              id: item.id,
              delivery_material: item.id,
              material_name: item.material_name,
              material_number: item.material_number,
              remain_quantity: item.remain_quantity,
              charge_value: this.getChargeValue(item, item.remain_quantity),
              total_quantity: item.remain_quantity,
              charge_unit: item.charge_unit,
              unit: item.unit,
              batch_number: item.batch_number,
            };
          });

          this.deliveryMaterialItems = [...materialItems];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeMaterial(index) {
      this.deliveryMaterialItems.splice(index, 1);
    },
    create() {
      if (this.deliveryMaterialItems.length == 0) {
        this.$message.warn(this.$t("未添加产品"));
        return;
      }

      let formData = {
        ...this.form,
        delivery_order: this.delivery_order_id,
        delivery_record_material_items: this.deliveryMaterialItems,
      };

      this.loading = true;
      deliveryRecordCreate(formData)
        .then((data) => {
          this.$message.success(this.$t("创建成功"));
          this.goBack();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    addSerialNumber(item) {
      item.total_quantity += 1;
    },
    getChargeValue(item, quantity) {
      if (item.charge_unit === "quantity") {
        return quantity || 0;
      } else if (item.charge_unit === "ton") {
        return NP.times(quantity, item.weight || 0, 0.001);
      } else if (item.charge_unit === "cube") {
        return NP.times(quantity, item.volume || 0);
      }
      return 0;
    },
    copyMaterial(item, index) {
      this.deliveryMaterialItems.splice(index, 0, { ...item });
    },
    changeQuantity(item) {
      item.charge_value = this.getChargeValue(item, item.total_quantity);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
